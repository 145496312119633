.character-panel {
  position: absolute;
  top: 24px;
  left: 26px;
  user-select: none;
}


.character-panel::after {
  content: '';
  position: absolute;
  top: 12px;
  right: 15px;
  box-sizing: border-box;
  height: 7px;
  width: 7px;
  border-style: solid;
  border-color: #111;
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
}

.character-picker {
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 36px;
  background: #f6f6f6;
  box-shadow: inset 0 3px 10px rgba(0,0,0,0.05);
  border: none;
  border-radius: 6px;
  min-width: 200px;
  font-size: 16px;
  text-indent: 12px;
  text-transform: capitalize;
  color: #222;
  cursor: pointer;
}

.character-picker:hover {
  background: #efefef;
}

.character-picker:focus {
  border: solid 1px #ccc;
  outline: none;
}


@media (prefers-color-scheme: dark) {
  .character-picker {
    background: rgba(255,255,255,0.1);
    color: #fff;
  }

  .character-picker:hover {
    background: rgba(255,255,255,0.15);
  }
  .character-panel::after {
    border-color: #999;
  }

}


@media (max-width: 600px) {
  .character-panel {
    position: relative;
    top: 0; left: 0;
    margin: 24px 24px 12px;
    display: flex;
  }

  .character-picker {
    flex: 1
  }
}

.edit-cards-panel {
  position: absolute;
  top: 24px;
  right: 26px;
  color: #aaa;
  user-select: none;
  font-size: 16px;
  line-height: 36px;
}

@media (max-width: 600px) {
  .edit-cards-panel {
    position: relative;
    left: 0; top: 0;
    margin: 12px 24px 0;
  }

  .edit-cards {
    float: right;
  }
}

.edit-cards {
  color: #222;
  height: 36px;
  background: #f6f6f6;
  box-shadow: inset 0 3px 10px rgba(0,0,0,0.05);
  display: inline-block;
  line-height: 36px;
  padding: 0 18px;
  margin-left: 20px;
  min-width: 60px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.edit-cards:hover {
  background: #efefef;
}

@media (prefers-color-scheme: dark) {
  .edit-cards {
    background: rgba(255,255,255,0.1);
    color: #fff;
  }
  .edit-cards:hover {
    background: rgba(255,255,255,0.2);
  }
}
