body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.card-container {
  flex: 1;
  padding: 90px 26px 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .card-container {
    padding: 24px 12px 30px;
  }
}

.footer {
  color: #aaa;
  text-align: center;
  padding: 0 16px 40px;
  line-height: 1.5;
}

.footer a {
  color: #888;
}

@media (prefers-color-scheme: dark) {
  html {
    background: #111;
  }
}
